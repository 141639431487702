@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800;900&display=swap');


body {
  background-color: #191B24;
  font-family: 'Nunito', sans-serif;
  color: white;
  }

::-webkit-scrollbar {
  width: 7px;         
  }

::-webkit-scrollbar-track {
  margin-top: 5px;
  margin-bottom: 5px;
  }
 
::-webkit-scrollbar-thumb {
  background-color: #8892B0;    
  border-radius: 14px;   
  }

  .no-flick{
    -webkit-transform:translate3d(0,0,0);
  }